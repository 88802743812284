@media (max-width: 1023px) {
  .main {
    min-height: 90vh;
    font-weight: 700;
    margin-top: 32px;
    width: 98vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-top {
    display: flex;
    flex-direction: column;
  }

  .main-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #004638;
    font-size: 13vmin;
    font-weight: 900;
    line-height: 8vmin;
    margin-bottom: 12vmin;
  }
  
  .tertiary-title {
    color: #82bf00;
    font-size: 6vmin;
    margin-bottom: 40px;
  }
  
  .main-text {
    color: #004638;
    font-size: 4vmin;
    line-height: 5vmin;
    text-align: justify;
    width: 95%;
  }
  
  .upper-text {
    margin-bottom: 5vmin;
  }

  .highlighted-text {
    color: #004638;
    background: linear-gradient(180deg,#f6f6f6 0%,#f6f6f6 30%,#d8f537 31%,#d8f537 100%);
    font-size: 6vmin;
    width: fit-content;
  }

  .links {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .link-group {
    display: flex;
  }
  
  .individual-link {
    background: #f1f4f5;
    margin: 4vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 42vmin;
    height: 42vmin;
    color: #004638;
  }

  .individual-link:hover {
    cursor: pointer;
    color: #82bf00;
  }
  
  .individual-link-text::after {
    color: #82bf00;
    content: "\f105";
    font-family: FontAwesome;
    margin-left: 2vmin;
  }

  .individual-link-image {
    width: 24vmin;
    height: 24vmin;
  }
  
}