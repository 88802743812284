@media (max-width: 1023px) {
  .dev {
    width: 97vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #004638;
  }
}