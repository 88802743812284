@media (min-width: 1024px) {
  
  .battery-project-container {
    max-width: 1280px;
    width: 98vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .battery-slide-1 {
    background-color: #f6f6f6;
    color: #004638;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
  }
  
  .battery-slide-1-top {
  
  }
  
  .battery-title {
    font-size: 40px;
    color: #f6f6f6;
    text-shadow: -2px 0 #004638, 0 2px #004638, 2px 0 #004638, 0 -2px #004638;
    border: 4px solid #004638;
    box-shadow: 4px 4px #004638;
    border-radius: 16px;
    padding: 8px;
  }
  
  .battery-slide-1-bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .battery-screenshot-1 {
    width: 640px;
    max-width: 50%;
    margin: 16px;
  }
  
  .battery-paragraph-1 {
    width: 480px;
    font-size: 24px;
  }
  
  .battery-slide-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url(../../Assets/Battery_Monitoring/background1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
  
  
  .battery-paragraph-2 {
    color: #004638;
    background: #d8f537;
    width: 720px;
    font-size: 24px;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 80px;
  }
  
  .battery-slide-3 {
    background-color: #f8f8e6;
    color: #004638;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .battery-slide-3-row {
    display: flex;
    justify-content: space-evenly;
    margin: 80px;
  }
  
  .battery-slide-3-bottom {
    height: 100vh;
    width: 100vw;
    background-image: url(../../Assets/Battery_Monitoring/BatteryBottomDesktop.png);
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .battery-slide-3-bottom-top {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  
  .battery-screenshot-3 {
    border-radius: 16px;
    width: 640px;
    max-width: 50vmax;
    margin: 16px;
  }
  
  .battery-paragraph-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 480px;
    font-size: 24px;
  }
  
  .battery-paragraph-3-bottom {
    margin-top: 80px;
    background-color: #f8f8e6;
    padding: 8px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 480px;
    font-size: 24px;
  }
  
  .youtube-link {
    border: 4px solid #004638;
    box-shadow: 4px 4px #004638;
    color: #004638;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 8px;
    animation: nothover 1s;
  }
  
  .youtube-link:hover {
    cursor: pointer;
    background-color:#004638;
    color: #f8f8e6;
    animation: hover 1s;
  }
  
  .youtube-link:active {
    margin-left: 4px;
    margin-top: 4px;
    box-shadow: 0px 0px #004638;
  }
}