@media (min-width: 1024px) {
  .experiences {
    margin: 40px 0px 40px 24px;
    min-height: 90vh;
    min-width: 560px;
    width: 700px;
  }
  
  
  .experience-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .experience-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .search-bar {
    background-color: #f6f6f6;
    color: #004638;
    border: 4px solid #004638;
    border-radius: 16px;
    font-size: 24px;
    padding: 8px;
    margin-left: 40px;
    margin-bottom: 40px;
    width: 400px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.5; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #004638;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
  
  
  
  .individual-experience {
    min-width: 560px;
    width: 700px;
    border-style: dashed none none none;
    border-color: #004638;
    color: #004638;
    margin: 0px 0px 8px 40px;
    padding: 32px 0px 32px 32px;
    box-shadow: 0px 0px black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    -webkit-animation: individualExp 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: individualExp 0.25s; /* Firefox < 16 */
     -ms-animation: individualExp 0.25s; /* Internet Explorer */
      -o-animation: individualExp 0.25s; /* Opera < 12.1 */
      animation: individualExp 0.25s;
  }
  @keyframes individualExp {
    from {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
  }
  /* Firefox < 16 */
  @-moz-keyframes individualExp {
    from {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes individualExp {
    from {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
  }
  /* Internet Explorer */
  @-ms-keyframes individualExp {
    from {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
  }
  /* Opera < 12.1 */
  @-o-keyframes individualExp {
    from {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
    to {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
  }
  
  
  .individual-experience:hover {
    cursor: pointer;
    background-color: #004638;
    color: #f6f6f6;
    margin: 0px 0px 8px 32px;
    box-shadow: 8px 8px black;
    -webkit-animation: individualExpHover 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: individualExpHover 0.25s; /* Firefox < 16 */
     -ms-animation: individualExpHover 0.25s; /* Internet Explorer */
      -o-animation: individualExpHover 0.25s; /* Opera < 12.1 */
      animation: individualExpHover 0.25s;
  }
  @keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
  }
  /* Firefox < 16 */
  @-moz-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
  }
  /* Internet Explorer */
  @-ms-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
  }
  /* Opera < 12.1 */
  @-o-keyframes individualExpHover {
    from {box-shadow: 0px 0px black; margin: 0px 0px 8px 40px; background-color: #f6f6f6; color: #004638;}
    to {box-shadow: 8px 8px black; margin: 0px 0px 8px 32px; background-color: #004638; color: #f6f6f6;}
  }
  
  
  .ie-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .ie-middle {
    text-align: start;
    margin-bottom: 16px;
    color: #82bf00;
  }
  
  .ie-bottom {
    display: flex;
  }
  
  .ie-institution {
    font-size: 24px;
    margin-right: 8px;
  }
  
  .ie-date {
    font-size: 12px;
    padding: 0px 4px 0px 4px;
    border: 1px solid #004638;
    border-radius: 24px;
    margin-right: 8px;
  }
  
  .ie-languages {
    font-size: 16px;
    font-weight: 900;
    display: flex;
    flex-wrap: wrap;
  }
  
  .languages {
    margin-right: 8px;
    border-left: 1px solid #004638;
    padding-left: 8px;
    margin-bottom: 8px;
  }
    
}
