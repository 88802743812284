@media (max-width: 1023px) {
  .battery-project-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .battery-slide-1 {
    background-color: #f6f6f6;
    color: #004638;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5vmin 0vmin 5vmin 0vmin;
  }
  
  .battery-slide-1-top {
  
  }
  
  .battery-title {
    font-size: 5vmin;
    color: #f6f6f6;
    text-shadow: -2px 0 #004638, 0 2px #004638, 2px 0 #004638, 0 -2px #004638;
    border: 0.5vmin solid #004638;
    box-shadow: 0.5vmin 0.5vmin #004638;
    border-radius: 2vmin;
    padding: 1vmin;
  }
  
  .battery-slide-1-bottom {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .battery-screenshot-1 {
    width: 80%;
    max-width: 50vmax;
    margin: 2vmin;
  }
  
  .battery-paragraph-1 {
    width: 90%;
    font-size: 3vmin;
  }
  
  .battery-slide-2 {
    background-color: #004638;
    background-image: url(../../Assets/Battery_Monitoring/background1.png);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 5vmin 0vmin 5vmin 0vmin;
    width: 100vw;
    height: 30vh;
  }
  
  .battery-paragraph-2 {
    color: #004638;
    background: #d8f537;
    width: 90%;
    font-size: 3vmin;
    padding: 3vmin;
    border-radius: 2vmin;
    margin-top: 10vmin;
    margin-bottom: 10vmin;
  }
  
  .battery-slide-3 {
    background-color: #f8f8e6;
    color: #004638;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
  }
  
  .battery-slide-3-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 10vmin;
    width: 95%
  }
  
  .battery-slide-3-bottom {
    height: 100vh;
    width: 100vw;
    background-image: url(../../Assets/Battery_Monitoring/BatteryBottomMobile.png);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .battery-slide-3-bottom-top {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .battery-screenshot-3 {
    border-radius: 2vmin;
    width: 80%;
    max-width: 50vmax;
    margin: 2vmin;
  }
  
  .battery-paragraph-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    font-size: 3vmin;
  }
  
  .battery-paragraph-3-bottom {
    margin-top: 10vmin;
    background-color: #f8f8e6;
    padding: 1vmin;
    border-radius: 3vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    font-size: 3vmin;
  }
  
  .youtube-link {
    border: 0.5vmin solid #004638;
    box-shadow: 0.5vmin 0.5vmin #004638;
    color: #004638;
    text-decoration: none;
    border-radius: 2vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vmin;
    padding: 1vmin;
  }
  
  .youtube-link:hover {
    cursor: pointer;
  }
  
  .youtube-link:active {
    margin-left: 0.5vmin;
    margin-top: 0.5vmin;
    box-shadow: 0px 0px #004638;
  }
}