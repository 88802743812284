@media (min-width: 1024px) {

  .ControlPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #d8f537;
  }

  .LeftControl {
    display: flex;
    flex-direction: column;
  }

  .RightControl {
    max-width: 1280px;
    width: 98vw;
    display: flex;
    justify-content: center;
    margin-top: -32px;
  }

  .Ac {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 128px;
  }
  
  .AcState {
    align-self: center;
    margin-bottom: 100px;
    width: 10vw;
    border-radius: 10px;
    padding: 3vh;
    background-color: #004638;
    color: #f6f6f6;
  }
  
  h1 {
    color: #f6f6f6;
  }
  
  .AcButtons {
    align-self: center;
    display: flex;
    width: 400px;
    justify-content: space-evenly;
  }
  
  .TurnOnButton {
    background-color: #004638;
    color: #f6f6f6;
    border: none;
    padding: 3vh;
    border-radius: 10px;
    font-size: larger;
  }
  
  .TurnOnButton:hover {
    cursor: pointer;
    background-color: #82bf00;
  }
  
  .TurnOnButton:active {
    cursor: pointer;
    background-color: #82bf00;
  }
  
  .TurnOffButton {
    background-color: #004638;
    color: #f6f6f6;
    border: none;
    padding: 3vh;
    border-radius: 10px;
    font-size: larger;
  }
  
  .TurnOffButton:hover {
    cursor: pointer;
    background-color: #bf0000;
  }
  
  .TurnOffButton:active {
    cursor: pointer;
    background-color: #bf0000;
  }
  
  .UserPageButton {
    background-color: #004638;
    color: #f6f6f6;
    height: 96px;
    width: 96px;
    font-size: 64px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 16px 0px 16px 100px;
  }
  
  .UserPageButton:hover {
    cursor: pointer;
    background-color: #82bf00;
  }
  
  .UserPageButton:active {
    background-color: #82bf00;
    color: #004638;
  }  

}