@media (min-width: 1024px) {

  .Charts {
    color: #004638;
    height: 400px;
    width: 700px;
    font-weight: bold;
    font-size: larger;
  }


}